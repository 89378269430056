import React from "react"

import HeaderServices from "../../components/header_services"

export default function FeePhilosophy() {
  const heroTitle = "Fee Philosophy"
  return (
    <HeaderServices heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>
        The initial consultation provided to you will be free of charge. This helps us to understand
        your financial objectives and will confirm how we can support you in working towards these.
        We will also discuss the cost and levels of our services both initially and throughout our
        relationship with you.
      </p>

      <p>
        We charge our services by way of a fee. These fees are based on a percentage of the assets
        under our advisory. You pay us a fee based on your portfolio size and we earn no other fees,
        commissions or kickbacks. We do well only when your portfolio does well.
      </p>

      <p>
        For our “Second Look” offering, we charge a one time lump sum fee depending on the
        complexity and the size of the portfolio and is typically subject to a maximum limit.
      </p>

      <p>All fees or charges are disclosed and agreed in advance of undertaking any work.</p>
    </HeaderServices>
  )
}
