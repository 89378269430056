import React from "react"

import HeaderWrapper from "./header_wrapper"
import NavServices from "./nav_services"

export default function HeaderServices({ heroTitle = null, children }) {
  const heroImage = "/images/slider/Services--Banner.jpg"
  const heroMobileImage = "/images/slider/mobile/Services--Banner.jpg"
  const breadcrumbSecondaryItem = "Services"
  const breadcrumbSecondaryItemUrl = "/services/offerings"

  return (
    <HeaderWrapper
      heroTitle={heroTitle}
      heroImage={heroImage}
      heroMobileImage={heroMobileImage}
      breadcrumbSecondaryItem={breadcrumbSecondaryItem}
      breadcrumbSecondaryItemUrl={breadcrumbSecondaryItemUrl}
      NavComponent={NavServices}
    >
      {children}
    </HeaderWrapper>
  )
}
