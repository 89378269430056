import React from "react"
import { Link } from "gatsby"

export default function NavServices() {
  return (
    <>
      <Link to="/" className="back--home">
        <i class="fas fa-chevron-left"></i> <span>Back to homepage</span>
      </Link>

      <ul class="sub--pages sub--pages-sm">
        <li>
          <Link to="/services/offerings" activeClassName="is--active">
            Offerings
          </Link>
        </li>
        <li>
          <Link to="/services/client-journey" activeClassName="is--active">
            Client Journey
          </Link>
        </li>
        <li>
          <Link to="/services/client-engagement" activeClassName="is--active">
            Client Engagement
          </Link>
        </li>
        <li>
          <Link to="/services/fee-philosophy" activeClassName="is--active">
            Fee Philosophy
          </Link>
        </li>
      </ul>
    </>
  )
}
